export class Angebot {
  name: string;
  routeVal: string;
  img: string;
  desc: string;
  constructor(inName: string, inRoute: string, inImg: string, inDesc: string) {
    this.name = inName;
    this.routeVal = inRoute;
    this.img = inImg;
    this.desc = inDesc;
  }
}

export class AngebotPicture {
  name: string;
  img: string;
  constructor(inName: string, inImg: string) {
    this.name = inName;
    this.img = inImg;
  }
}
