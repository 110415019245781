<div class="container-fluid scharr-section">
  <div class="scharr-cont-black">
    <div class="row justify-content-center">
      <div class="col-3 container">
        <h2 class="scharr-text-centered scharr-marg-res-md">Unsere Partner</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="container">
          <a href="https://www.kessler-gk.ch/" target="_blank">
            <img src="../../../assets/img/partner/kessler.jpeg" class="img-fluid mx-auto d-block">
          </a>
          <h5 class="">Gebrüder Kessler</h5>
          <p>Grüningen</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="container">
          <a href="https://schmid-kranarbeiten.ch/" target="_blank">
            <img src="../../../assets/img/partner/kranareiten.jpeg" class="img-fluid  mx-auto d-block">
          </a>
          <h5 class="">Schmid Kran</h5>
          <p>Grüningen</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="container">
          <a href="http://www.messmerholzbau.ch/" target="_blank">
            <img src="../../../assets/img/partner/messmer.jpeg" class="img-fluid  mx-auto d-block">
          </a>
          <h5 class="">Messmer Holzbau GmbH</h5>
          <p>Hombrechtikon</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="container">
          <a href="https://www.matter-ag.ch/" target="_blank">
            <img src="../../../assets/img/partner/matter.jpeg" class="img-fluid  mx-auto d-block">
          </a>
          <h5 class="">Matter Metallbau AG</h5>
          <p>Luzern</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="container">
          <a href="https://www.sinfla.ch/" target="_blank">
            <img src="../../../assets/img/partner/sinfla.jpeg" class="img-fluid  mx-auto d-block">
          </a>
          <h5 class="">Sinfla</h5>
          <p>Mönchaltdorf</p>
        </div>
      </div>
    </div>
  </div>
</div>
