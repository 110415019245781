import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AngebotComponent } from './angebot/angebot.component';
import { ChaletComponent } from './chalet/chalet.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { MueckComponent } from './angebot/mueck/mueck.component';
import { NewsComponent } from './news/news.component';
import { ReferenzenComponent } from './referenzen/referenzen.component';
import { SchreinereiComponent } from './schreinerei/schreinerei.component';
import { TeamComponent } from './team/team.component';
import { AussenComponent } from './angebot/aussen/aussen.component';
import { InnenComponent } from './angebot/innen/innen.component';
import { MessebauComponent } from './angebot/messebau/messebau.component';
import { LadenbauComponent } from './angebot/ladenbau/ladenbau.component';
import { SpezialComponent } from './angebot/spezial/spezial.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { HomeIntroComponent } from './home/home-intro/home-intro.component';
import { HomeOverviewComponent } from './home/home-overview/home-overview.component';
import { HomePartnerComponent } from './home/home-partner/home-partner.component';
import { HomeKontaktComponent } from './home/home-kontakt/home-kontakt.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { VersionDialogComponent } from './version-dialog/version-dialog.component';
import { VisualComponent } from './angebot/visual/visual.component';
import { PartnerComponent } from './partner/partner.component';
import { HomeNewsComponent } from './home/home-news/home-news.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AngebotComponent,
    ChaletComponent,
    KontaktComponent,
    MueckComponent,
    NewsComponent,
    ReferenzenComponent,
    SchreinereiComponent,
    TeamComponent,
    AussenComponent,
    InnenComponent,
    MessebauComponent,
    LadenbauComponent,
    SpezialComponent,
    HomeIntroComponent,
    HomeOverviewComponent,
    HomePartnerComponent,
    HomeKontaktComponent,
    VersionDialogComponent,
    VisualComponent,
    PartnerComponent,
    HomeNewsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
