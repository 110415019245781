<div class="container">
  <div id="timeline-content">
    <h1>Schreinerei</h1>

    <ul class="timeline">
      <li class="event" data-date="2004">
        <mat-card class="card-item">
          <mat-card-header>
            <mat-card-title>Gründung</mat-card-title>
            <mat-card-subtitle>der Einzelfirma Schreinerei Scharrenberg an der Oberdorfstrasse 3 in 8617 Mönchaltorf
            </mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/logoSchreinerei.jpeg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </li>
      <li class="event" data-date="2005">
        <mat-card class="card-item">
          <mat-card-header>
            <mat-card-title>Erste Aufträge</mat-card-title>
            <mat-card-subtitle>vom Flughafen Zürich und Bächli Bergsport AG</mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/flughafenUndBaechli.jpeg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </li>
      <li class="event" id="date" data-date="2013">
        <mat-card class="card-item">
          <mat-card-header>
            <mat-card-title>Umzug</mat-card-title>
            <mat-card-subtitle>der Schreinerei Scharrenberg in die Mettlenbachstrasse 19 in 8617 Mönchaltorf
            </mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/umzug.jpeg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
        <mat-card class="card-item mt-3">
          <mat-card-header>
            <mat-card-title>Mitgliedschaft</mat-card-title>
            <mat-card-subtitle>beim VSSM</mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/vssm200.jpeg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
        <mat-card class="card-item mt-3">
          <mat-card-header>
            <mat-card-title>Unterstüzung</mat-card-title>
            <mat-card-subtitle>Jugendprojekt LIFT</mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/Lift_Projekt.jpg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </li>
      <li class="event" data-date="2014">
        <mat-card class="card-item">
          <mat-card-header>
            <mat-card-title>Erster Lehrling</mat-card-title>
            <mat-card-subtitle>für die EFZ Ausbildung</mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/Lehrbetrieb.jpg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </li>
      <li class="event" data-date="2015">
        <mat-card class="card-item">
          <mat-card-header>
            <mat-card-title>Umwandlung</mat-card-title>
            <mat-card-subtitle>von der Einzelfirma zur Schreinerei Scharrenberg GmbH</mat-card-subtitle>
          </mat-card-header>
          <div mat-card-image></div>
          <mat-card-content class="card-item-cont">
            <img src="../../assets/img/schreinerei/gmbh.jpeg" class="img-fluid rounded">
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </li>
    </ul>
  </div>
  <div class="content-section text-center">
    <div class="schreinerei-pic-section">
      <div class="container">
        <div class="col-lg-8 col-lg-offset-2">
        </div>
      </div>
    </div>
  </div>
</div>

