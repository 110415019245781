import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomeComponent} from './home/home.component';
import {AussenComponent} from './angebot/aussen/aussen.component';
import {InnenComponent} from './angebot/innen/innen.component';
import {SpezialComponent} from './angebot/spezial/spezial.component';
import {MessebauComponent} from './angebot/messebau/messebau.component';
import {LadenbauComponent} from './angebot/ladenbau/ladenbau.component';
import {SchreinereiComponent} from './schreinerei/schreinerei.component';
import {TeamComponent} from './team/team.component';
import {AngebotComponent} from './angebot/angebot.component';
import {ReferenzenComponent} from './referenzen/referenzen.component';
import {VisualComponent} from "./angebot/visual/visual.component";
import {MueckComponent} from "./angebot/mueck/mueck.component";
import {KontaktComponent} from "./kontakt/kontakt.component";
import {PartnerComponent} from "./partner/partner.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'schreinerei',
    component: SchreinereiComponent
  },
  {
    path: 'angebot',
    component: AngebotComponent
  },
  {
    path: 'referenzen',
    component: ReferenzenComponent
  },
  {
    path: 'messebau',
    component: MessebauComponent
  },
  {
    path: 'ladenbau',
    component: LadenbauComponent
  },
  {
    path: 'aussen',
    component: AussenComponent
  },
  {
    path: 'innen',
    component: InnenComponent
  },
  {
    path: 'spezial',
    component: SpezialComponent
  },
  {
    path: 'mueck',
    component: MueckComponent
  },
  {
    path: 'visual',
    component: VisualComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: 'partner',
    component: PartnerComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
