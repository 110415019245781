<div class="container-fluid">
  <div class="row mt-3">
    <div *ngFor="let angebot of angeboteArr" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <figure class="m-1">
        <div class="media2" [style.background-image]="'url('+ angebot.img +')'"></div>
        <figcaption class="first_fig">
          <svg viewBox="0 0 200 200" version="1.1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
          </svg>
          <div class="title2">
            <h4>{{angebot.name}}</h4>
          </div>

          <div class="body2">
            <p>{{angebot.desc}}</p>
          </div>
        </figcaption>
        <a (click)="onNavTo(angebot.routeVal)"></a>
      </figure>
    </div>
  </div>
  <div class="content-section text-center">
    <div class="team-pic-section">
      <div class="container">
        <div class="col-lg-8 col-lg-offset-2">

        </div>
      </div>
    </div>
  </div>
</div>
