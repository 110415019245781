export class ReferenzPicture {
  public name: string;
  public img: string;
  public url: string;
  constructor( inImg: string, inName: string, inUrl: string) {
    this.name = inName;
    this.img = inImg;
    this.url = inUrl;
  }
}
