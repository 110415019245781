import { Injectable } from '@angular/core';

interface NavPosition {
  angebotPos: any;
  schreinereiPos: any;
  referenzenPos: any;
  kontaktPos: any;
  teamPos: any;
  angebotBtn: any;
  schreinereiBtn: any;
  referenzenBtn: any;
  kontaktBtn: any;
  teamBtn: any;
  newsPos: any;
  newsBtn: any;
  top: any;
}

class NavigationHelper {
  //private position: NavPosition;

  constructor() {
    this.setPosition();
  }

  public setPositionActive(pos: string) {

    this.setPosition();

    // if (pos >= this.position.newsPos && pos < this.position.schreinereiPos ) {
    //   this.position.newsBtn.addClass('active');
    // } else {
    //   this.position.newsBtn.removeClass('active');
    // }

    // if (pos >= this.position.schreinereiPos && pos < this.position.teamPos ) {
    //   this.position.schreinereiBtn.addClass('active');
    // } else {
    //   this.position.schreinereiBtn.removeClass('active');
    // }

    // if (pos >= this.position.teamPos && pos < this.position.angebotPos || pos === this.position.angebotPos ) {
    //   this.position.teamBtn.addClass('active');
    // } else {
    //   this.position.teamBtn.removeClass('active');
    // }

    // if (pos >= this.position.angebotPos && pos < this.position.referenzenPos || pos === this.position.referenzenPos) {
    //   this.position.angebotBtn.addClass('active');
    // } else {
    //   this.position.angebotBtn.removeClass('active');
    // }

    // if (pos >= this.position.referenzenPos && pos < this.position.kontaktPos || pos === this.position.kontaktPos ) {
    //   this.position.referenzenBtn.addClass('active');
    // } else {
    //   this.position.referenzenBtn.removeClass('active');
    // }

    // if (pos >= this.position.kontaktPos || pos === this.position.kontaktPos) {
    //   this.position.kontaktBtn.addClass('active');
    // } else {
    //   this.position.kontaktBtn.removeClass('active');
    // }
  }

  private setPosition() {
    //  this.position = {
    //   newsPos: document.getElementById('news') ? document.getElementById('news').offsetTop : "",
    //   angebotPos: document.getElementById('about') ? document.getElementById('about').offsetTop : "",
    //   schreinereiPos: document.getElementById('schreinerei') ? document.getElementById('schreinerei').offsetTop : "",
    //   referenzenPos: document.getElementById('portfolio') ? document.getElementById('portfolio').offsetTop : "",
    //   kontaktPos: document.getElementById('contact') ? document.getElementById('contact').offsetTop : "",
    //   teamPos: document.getElementById('team1') ? document.getElementById('team1').offsetTop : "",
    //   newsBtn: $('#navNewsId'),
    //   angebotBtn: $('#navAngebotId'),
    //   schreinereiBtn: $('#navSchreinereiId'),
    //   referenzenBtn: $('#navReferenzenId'),
    //   kontaktBtn: $('#navKontaktId'),
    //   teamBtn: $('#navTeamId'),
    //   top: $('#pageTop')
    // };
  }
}

@Injectable({
  providedIn: 'root'
})

export class NavService {
  private NavObj: NavigationHelper | undefined;

  constructor() { }

  setNavButton(actPos: string) {
    if (!this.NavObj) {
      this.NavObj = new NavigationHelper();
    }

    this.NavObj.setPositionActive(actPos);

  }
}
