import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ReferenzPicture} from "./referenz";

@Component({
  selector: 'app-referenzen',
  templateUrl: './referenzen.component.html',
  styleUrls: ['./referenzen.component.css']
})
export class ReferenzenComponent implements OnInit {

  public picReferenz: ReferenzPicture[];

  constructor( private router: Router) {
    this.picReferenz  = [
      new ReferenzPicture("../../assets/img/referenzen/firma/alpanach.jpeg", "", ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/alurex.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/asprosport.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/baechli.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/bea.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/blattundbluete.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/bngraphic.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/buergenstock.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/burgflora.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/bvlgari.jpwebpage g", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/casafantastica.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/caviarhouse.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/creativa.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/diakoniewerk.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/domotera.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/ethzh.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/expormobilia.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/famexpo.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/feinkostlehman_l.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/flughafenzh.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/flumroc.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/fontoroca.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/georgrutz.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/gutauto.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/intusdata.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/joerimann.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/kapozh.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/kfp.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/kmsracing.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/lamro.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/landliebe.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/luggen.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/mammut.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/meier.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/meilen.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/netcom.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/nievergelt.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/novartis.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/parkinn.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/peekabead.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/presentsrv.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/renfer.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/rentrentner.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/ringier.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sandoz.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/scharri.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/schlosseisbahn.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/schweiz.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/screenpro.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sinfla.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/smartec.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sonnhalde.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sonntalk.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sossicherheit.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/spowag.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/stadtzhpolizei.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/swissvax.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/sybillemathis.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/thecircle.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/werbeanstalt.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/zhaw.jpeg", "",  ""),
      new ReferenzPicture("../../assets/img/referenzen/firma/zzwancor.jpeg", "",  "")
    ];
  }

  ngOnInit() {

  }

}
