import {Component, OnInit, ViewChild} from '@angular/core';
import {NavService} from './service/nav.service';
import {Router} from '@angular/router';
import {PwaService} from "./service/pwa.service";
import {MatSidenav} from "@angular/material/sidenav";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NavService]
})
export class AppComponent implements OnInit {

  // @ts-ignore
  @ViewChild('snav', {static: false}) sidenav: MatSidenav;

  constructor( private router: Router,
               private navService: NavService,
               private pwaService: PwaService) {

  }

  ngOnInit() {
    this.initUpdatePwa();
  }

  initUpdatePwa() {
    this.pwaService.checkForUpdate();
  }

  onClickSection(sectionId: string) {
    this.sidenav.close();
    this.router.navigate([sectionId]);
  }
}
