import {Component, OnInit} from '@angular/core';
import {TeamOrg, TeamPicture} from "./team";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  public teamScharr: TeamOrg;

  constructor() {
    this.teamScharr = new TeamOrg(new TeamPicture("Christoph Scharrenberg", "Geschäftsführer", "../../assets/img/team/Christoph_400.jpg"),
      [
        new TeamPicture("Uli Schwarz", "Projektleiter / Holztechniker", "../../assets/img/team/UliSchwarz_400.jpg"),
        new TeamPicture("Beat Altorfer", "Schreiner EFZ", "../../assets/img/team/BeatAltorfer_400.jpg"),
        new TeamPicture( "Florian Schmid", "Schreiner EFZ", "../../assets/img/team/FlorianSchmid_400.jpg"),
        new TeamPicture("Janosch Gasser", "Freelancer", "../../assets/img/team/JanoschGasser_400.jpg")
      ]
    )
  }

    ngOnInit()
    {
    }

  }
