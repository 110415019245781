import { Component, OnInit } from '@angular/core';
import {NavService} from '../../service/nav.service';
import {Router} from '@angular/router';
import {AngebotPicture} from "../angebot";

@Component({
  selector: 'app-spezial',
  templateUrl: './spezial.component.html',
  styleUrls: ['./spezial.component.css'],
  providers: [
    NavService
  ]
})
export class SpezialComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name:"1", img:"../../../assets/img/referenzen/spez/spez12.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez13.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/1_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/2_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/5_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/7_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/4_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/8_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/9_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/11_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/13_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/14_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/15_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez1.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez2.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez3.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez4.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez8.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/12_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez6.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez5.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez9.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez7.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez10.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/spez11.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/spez/6_spezialprojekte_20191110-min.JPG"},
    {name:"1", img:"../../../assets/img/referenzen/spez/3_spezialprojekte_20191110-min.JPG"}
  ];

  constructor( private router: Router, private navService: NavService ) {

  }

  ngOnInit() {
  }
}
