<div class="container-fluid scharr-section">
  <div class="scharr-cont-black">
    <div class="row justify-content-center">
      <div class="col-3 container">
        <h2 class="scharr-text-centered scharr-marg-res-md">Neuigkeiten</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-10 col-sm-11">
        <div class="container m-3">
          <mat-list class="pb-3">
            <mat-divider></mat-divider>
<!--            <div mat-subheader>17.09.2023</div>-->
<!--            <mat-list-item>-->
<!--              &lt;!&ndash;              <mat-icon mat-list-icon>folder</mat-icon>&ndash;&gt;-->
<!--              <div mat-line>-->
<!--                <h3>-->
<!--                  Der Nachwuchs ist uns wichtig!-->
<!--                </h3>-->
<!--              </div>-->
<!--              <div mat-line>-->
<!--                Nicht nur in der Lehrlingsförderung. Wir unterstützen auch den Nachwuchs des FC Grüningen.-->
<!--                <a href="https://www.fcgrueningen.ch/news/merci-schreinerei-scharrenberg/" target="_blank"> Link FC Grüningen </a>-->
<!--              </div>-->
<!--              <mat-divider></mat-divider>-->
<!--            </mat-list-item>-->
            <div mat-subheader>09.04.2024</div>
            <mat-list-item>
              <!--              <mat-icon mat-list-icon>folder</mat-icon>-->
              <div mat-line>
                <h3>
                  Frühlingserwachen - Mücken, Bienen, Fliegen
                </h3>
              </div>
              <div mat-line>
                Damit Sie nur angenehme Gäste auf Besuch haben. Wir haben die Lösung.
                <a href="#" [routerLink]="['/mueck']"> Insektenschutz  </a>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
<!--            <mat-divider></mat-divider>-->
            <mat-divider></mat-divider>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
</div>
