<div class="scharr-header">
  <div class="scharr-intro-shadow">
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-12">
          <div class="scharr-intro-text">Schreinerei Scharrenberg</div>
        </div>
        <div class="col-12">
          <div class="scharr-intro-content">
            <img src="../../assets/img/scharr1a_eichel.png" class="img-fluid">
          </div>
        </div>
        <div class="col-12">
          <div class="scharr-intro-text">
            Wir sind
          </div>
        </div>
        <div class="col-12">
          <div class="row justify-content-md-center scharr-bg-gold-shadow">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 ">
              <div class="scharr-marg-res-sm">
                <div class="card scharr-card">
                  <div class="card-body">
                    <h5 class="card-title">
                      <a (click)="onNavTo('aussen')" class="stretched-link scharr-uppercase">Schreiner</a>
                    </h5>
                  </div>
                  <div class="text-center"></div>
                  <img src="../../assets/img/referenzen/innen/8_innen_20191110.jpg"
                       class="card-img-top d-block img-fluid scharr-pic-max">
                  <div class="text-center"></div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div class="scharr-marg-res-sm">
                <div class="card scharr-card">
                  <div class="card-body">
                    <h5 class="card-title">
                      <a (click)="onNavTo('messebau')" class="stretched-link scharr-uppercase">Messebauer</a>
                    </h5>
                  </div>
                  <img src="../../assets/img/referenzen/messe/13_messebau_20191110.JPG"
                       class="card-img-top img-fluid scharr-pic-max">
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div class="scharr-marg-res-sm">
                <div class="card scharr-card">
                  <div class="card-body">
                    <h5 class="card-title">
                      <a (click)="onNavTo('ladenbau')" class="stretched-link scharr-uppercase">Ladenbauer</a>
                    </h5>
                  </div>
                  <img src="../../assets/img/referenzen/laden/2_ladenbau_20191110.JPG"
                       class="card-img-top img-fluid scharr-pic-max">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
