import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['./home-overview.component.css']
})
export class HomeOverviewComponent implements OnInit, AfterViewInit {

  constructor(private router: Router) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }

  onClickRouting(routVal: any) {
    this.router.navigate( [ routVal] );
  }

}
