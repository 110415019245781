<mat-toolbar>
  <button mat-icon-button (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="header-title">Schreinerei Scharrenberg GmbH, 043 277 85 00</span>
</mat-toolbar>

<mat-sidenav-container (backdropClick)="snav.close()">
  <mat-sidenav #snav [mode]="'over'" (keydown.escape)="snav.close()">
    <mat-nav-list>
      <!-- <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a> -->
      <a mat-list-item class="page-scroll" (click)="onClickSection('home')">Home</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('angebot')">Angebot</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('schreinerei')">Schreinerei</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('team')">Team</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('kontakt')">Kontakt</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('referenzen')">Referenzen</a>
      <a mat-list-item class="page-scroll" (click)="onClickSection('partner')">Partner</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>



