import { Component, OnInit } from '@angular/core';
import {NavService} from '../../service/nav.service';
import {Router} from '@angular/router';
import {AngebotPicture} from "../angebot";

@Component({
  selector: 'app-aussen',
  templateUrl: './aussen.component.html',
  styleUrls: ['./aussen.component.css'],
  providers: [
    NavService
  ]
})
export class AussenComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name:"1", img:"../../../assets/img/referenzen/aussen/aussen10.jpeg"},
    {name:"2", img:"../../../assets/img/referenzen/aussen/aussen3.jpeg"},
    {name:"3", img:"../../../assets/img/referenzen/aussen/aussen2.jpeg"},
    {name:"4", img:"../../../assets/img/referenzen/aussen/aussen4.jpeg"},
    {name:"5", img:"../../../assets/img/referenzen/aussen/aussen5.jpeg"},
    {name:"6", img:"../../../assets/img/referenzen/aussen/aussen6.jpeg"},
    {name:"7", img:"../../../assets/img/referenzen/aussen/aussen7.jpeg"},
    {name:"8", img:"../../../assets/img/referenzen/aussen/aussen8.jpeg"},
    {name:"9", img:"../../../assets/img/referenzen/aussen/aussen9.jpeg"},
    {name:"13", img:"../../../assets/img/referenzen/aussen/aussen13.jpeg"},
    {name:"12", img:"../../../assets/img/referenzen/aussen/aussen12.jpeg"},
    {name:"11", img:"../../../assets/img/referenzen/aussen/aussen11.jpeg"},
    {name:"14", img:"../../../assets/img/referenzen/aussen/aussen14.jpeg"},
    {name:"16", img:"../../../assets/img/referenzen/aussen/aussen16.jpeg"},
    {name:"15", img:"../../../assets/img/referenzen/aussen/aussen15.jpeg"},
    {name:"18", img:"../../../assets/img/referenzen/aussen/aussen18.jpeg"},
    {name:"17", img:"../../../assets/img/referenzen/aussen/aussen17.jpeg"},
    {name:"10", img:"../../../assets/img/referenzen/aussen/aussen1.jpeg"}
  ];

  constructor( private router: Router, private navService: NavService ) {

  }

  ngOnInit() {
  }

}
