<div class="container-fluid scharr-section">
  <div class="d-md-flex flex-md-equal felx-sm-equal d-sm-flex w-100 my-md-3 pl-md-3 flex-sm-column flex-md-row">
    <div class="scharr-cont-black mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden order-1 scharr-hover"
         (click)="onClickRouting('angebot')">
      <div class="my-3 p-3">
        <h2 class="display-5">Angebot</h2>
        <p class="lead">Wir machen für Sie...</p>
      </div>
      <div class="bg-dark shadow-sm mx-auto scharr-over-cont scharr-over-ang-pic">

      </div>
    </div>
    <div
      class="scharr-cont-yellow  mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden order-2 scharr-hover"
      (click)="onClickRouting('schreinerei')">
      <div class="my-3 py-3">
        <h2 class="display-5">Schreinerei</h2>
        <p class="lead">Unser Werdegang...</p>
      </div>
      <div class="bg-light shadow-sm mx-auto scharr-over-cont scharr-over-schrein-pic">

      </div>

    </div>
  </div>
  <div
    class="d-md-flex flex-md-equal felx-sm-equal d-sm-flex w-100 my-md-3 pl-md-3 flex-sm-column-reverse flex-md-row"
    (click)="onClickRouting('team')">
    <div class="scharr-cont-yellow  mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden scharr-hover">
      <div class="my-3 p-3">
        <h2 class="display-5">Team</h2>
        <p class="lead">Wer sind wir...</p>
      </div>
      <div class="bg-dark shadow-sm mx-auto scharr-over-cont scharr-over-team-pic">

      </div>

    </div>
    <div class="scharr-cont-black mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden scharr-hover"
         (click)="onClickRouting('referenzen')">
      <div class="my-3 py-3">
        <h2 class="display-5">Referenzen</h2>
        <p class="lead">Unser Referenzen...</p>
      </div>
      <div class="bg-light shadow-sm mx-auto scharr-over-cont scharr-over-ref-pic">
      </div>
    </div>
  </div>
</div>
