import { Injectable } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {MatDialog} from '@angular/material/dialog';
import {VersionDialogComponent} from '../version-dialog/version-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  constructor(public updates: SwUpdate, public dialog: MatDialog) {

  }

  checkForUpdate() {
    this.updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      this.updates.activateUpdate().then(() => document.location.reload());
      // const dialogRef = this.dialog.open(VersionDialogComponent, {
      //    width: '250px',
      //    data: { version: event.available }
      // });
      //
      //   dialogRef.afterClosed().subscribe(result => {
      //    console.log('The dialog was closed');
      //    if (result) {
      //     this.updates.activateUpdate().then(() => document.location.reload());
      //  }
      // });
    });
    this.updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }
}
