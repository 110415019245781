
<app-home-intro></app-home-intro>
<app-home-news></app-home-news>
<app-home-overview></app-home-overview>
<app-home-partner></app-home-partner>
<app-home-kontakt></app-home-kontakt>

<footer>
  <div class="container text-center">
  </div>
</footer>
