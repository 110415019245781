import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AngebotPicture} from "../angebot";

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.css']
})
export class VisualComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name:"1", img:"../../../assets/img/referenzen/vis/vis13.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis12.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis11.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis14.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis1.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis2.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis3.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis4.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis5.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis6.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis7.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis8.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis9.jpg"},
    {name:"1", img:"../../../assets/img/referenzen/vis/vis10.jpg"},
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
