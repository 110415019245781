export class TeamPicture {
  name: string;
  position: string;
  img: string;

  constructor(inName: string, inPosition: string, inImg: string) {
    this.name = inName;
    this.img = inImg;
    this.position = inPosition;
  }


}

export class TeamOrg {
  chef: TeamPicture;
  employee: TeamPicture[];
  constructor(chefObj : TeamPicture, employees: TeamPicture[]) {
    this.chef = chefObj;
    this.employee = employees;
  }
}

