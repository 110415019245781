<div class="container content-section text-center">
  <div class="row mt-4">
    <div class="col-lg-12 text-center">
      <h2 class="section-heading">Angebot Messebau</h2>
    </div>
  </div>
  <div class="row">
  <div *ngFor="let pic of picAngebot" class="col-lg-6 col-md-8 col-sm-10 mt-3 mb-3">
      <div class="scharr-card-gall">
        <img src="{{pic.img}}" class="img-fluid"/>
      </div>
    </div>
  </div>
</div>
