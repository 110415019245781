import { Component, OnInit } from '@angular/core';
import {PartnerPicture} from "./partner";
import {ReferenzPicture} from "../referenzen/referenz";

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

  public picPartner: PartnerPicture[];

  constructor() {
    this.picPartner = [
      new PartnerPicture("../../assets/img/partner/kessler.jpeg", "", ""),
      new PartnerPicture("../../assets/img/partner/kranareiten.jpeg", "", ""),
      new PartnerPicture("../../assets/img/partner/matter.jpeg", "", ""),
      new PartnerPicture("../../assets/img/partner/messmer.jpeg", "", ""),
      new PartnerPicture("../../assets/img/partner/sinfla.jpeg", "", "")
    ];
  }

  ngOnInit(): void {
  }

}
