<div class="container">
  <div class="row mt-4">
    <div class="col-lg-12 text-center">
      <h2 class="section-heading">Referenzen</h2>
      <br>
      <p>Wann dürfen wir Sie als unsere Referenz aufführen</p>
    </div>
  </div>
  <div class="row justify-content-center  text-center">
    <div *ngFor="let pic of picReferenz" class="col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
      <div class="scharr-card-gall">
        <img src="{{pic.img}}" class="img-fluid"/>
      </div>
    </div>
  </div>
</div>
